<template>
  <page-view :title="title">
    <a-card :bordered="false">
      <a-space>
        <a-input placeholder="商品名" v-model="queryParam.name" />
        <a-button @click="$refs.table.refresh(false)">
          查询
        </a-button>
        <a-button @click="insert">
          新增
        </a-button>
        <a-button @click="batchUpDown(true)">
          批量上架
        </a-button>
        <a-button @click="batchUpDown(false)">
          批量下架
        </a-button>
      </a-space>
      <s-table
        style="margin-top: 10px"
        ref="table"
        size="small"
        rowKey="id"
        :columns="columns"
        :data="dataSource"
        :rowSelection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange
        }"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <template slot="sort" slot-scope="text, record">
          <span v-if="!editObj[record.id]">
            {{ text }}
            <a-icon type="edit" @click="addEdit(record)" />
          </span>
          <a-space direction="vertical" v-else>
            <a-input v-model="editObj[record.id].sort" style="width: 60px;" />
            <a-space>
              <a @click="cancelEdit(record.id)">取消</a>
              <span style="margin: 0 2px;">|</span>
              <a @click="editAdr(record.id)">确认</a>
            </a-space>
          </a-space>
        </template>
        <template slot="picture" slot-scope="text">
          <img
            :src="text"
            @click="() => (preview = { show: true, image: text })"
            style="width: 45px;height: 45px;"
          />
        </template>
        <template slot="enabled" slot-scope="text, record">
          <a style="margin-left: 8px;" @click="updateEnabled(record)">
            <span style="color: #6f766f" v-if="text">下架</span>
            <span style="color: #ff0707" v-else>上架</span>
          </a>
        </template>
        <template slot="operation" slot-scope="text, record">
          <a-popconfirm
            title="确认删除，不可恢复哦?"
            @confirm="() => deleteGoods(record)"
          >
            <a href="javascript:;">删除</a>
          </a-popconfirm>
          <a style="margin-left: 8px;" @click="update(record)">修改</a>
        </template>
      </s-table>
      <a-modal
        title="编辑"
        :width="640"
        :visible="edit.show"
        v-if="edit.show"
        :maskClosable="false"
        @cancel="handleCancel"
        @ok="handleOk"
      >
        <a-select
          show-search
          :filter-option="filterOldOption"
          class="top5"
          style="width: 100%"
          v-model="edit.id"
          @change="handleSelectOld"
          placeholder="请选择已有商品（编辑无效）"
        >
          <a-select-option
            v-for="item in goodsAll"
            :key="item.id"
            :value="item.id"
          >{{ `${item.name}#${item.code}#${item.stock}` }}</a-select-option
          >
        </a-select>
        <a-select
          class="top5"
          style="width: 100%"
          v-model="edit.partitionId"
          placeholder="请选择商品分区"
        >
          <a-select-option
            v-for="item in partitionList"
            :key="item.id"
            :value="item.id"
          >{{ item.name }}</a-select-option
          >
        </a-select>
        <a-input
          class="top5"
          placeholder="商品编号"
          v-model="edit.code"
        />
        <!-- （如果不接通第三方平台进行发货，请勿填写） -->
        <a-input class="top5" placeholder="商品名" v-model="edit.name" />
        <a-input-number
          class="top5"
          style="width: 100%;"
          :precision="2"
          placeholder="现价"
          v-model="edit.price"
        />
        <a-input-number
          class="top5"
          style="width: 100%;"
          :precision="3"
          placeholder="官方提成(收到金额=现价-官方提成)"
          v-model="edit.adminPrice"
        />
        <a-input-number
          class="top5"
          style="width: 100%;"
          :precision="2"
          placeholder="重量"
          v-model="edit.weight"
        />
        <a-input-number
          class="top5"
          style="width: 100%;"
          :precision="0"
          placeholder="库存"
          v-model="edit.stock"
        />
        <a-upload
          :multiple="false"
          :showUploadList="false"
          listType="picture"
          :customRequest="customRequest"
          accept="image/gif,image/png,image/jpeg,image/webp"
        >
          <img
            v-if="preview.image"
            :src="preview.image"
            style="width: 45px;height: 45px;"
          />
          <span v-else>上传主图</span>
        </a-upload>
      </a-modal>
      <a-modal
        :visible="preview.show"
        :footer="null"
        @cancel="() => (preview.show = false)"
      >
        <img style="width: 100%" :src="preview.image" />
      </a-modal>
    </a-card>
  </page-view>
</template>
<script>
import { STable } from '@/components'
import { PageView } from '@/layouts'
import {
  goodsList,
  goodsListAll,
  goodsInsert,
  goodsUpdate,
  goodsPartitionList,
  filePreUpload,
  uploadOSS
} from '@/api'
import { formatDate, uuid } from '@/utils/util'
// import lrz from 'lrz'

export default {
  props: {
    admin: {
      type: Boolean,
      default: false
    },
    uid: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      selectedRowKeys: [],
      selectedRows: [],
      preview: { image: '', show: false },
      partitionList: [],
      goodsAll: [],
      hasInsert: true,
      edit: { show: false },
      queryParam: {},
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '排序',
          dataIndex: 'sort',
          scopedSlots: { customRender: 'sort' }
        },
        {
          title: '主图',
          dataIndex: 'picture',
          scopedSlots: { customRender: 'picture' }
        },
        {
          title: '商品名',
          dataIndex: 'name'
        },
        {
          title: '售价',
          dataIndex: 'price',
          sorter: true
        },
        {
          title: '官方提成',
          dataIndex: 'adminPrice',
          sorter: true
        },
        {
          title: '重量',
          dataIndex: 'weight',
          sorter: true
        },
        {
          title: '库存',
          dataIndex: 'stock',
          sorter: true,
          width: '10%'
        },
        {
          title: '销量',
          dataIndex: 'sales',
          sorter: true,
          width: '10%'
        },
        {
          title: '上下架',
          dataIndex: 'enabled',
          scopedSlots: { customRender: 'enabled' },
          sorter: true,
          width: '10%'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          sorter: true,
          customRender: formatDate
        },
        {
          title: '修改时间',
          dataIndex: 'updateTime',
          sorter: true,
          customRender: formatDate
        },
        {
          title: '操作',
          dataIndex: 'id',
          scopedSlots: { customRender: 'operation' }
        }
      ],
      dataSource: parameter => {
        const extra = {
          admin: this.admin || undefined,
          uid: this.uid || undefined
        }
        return goodsList(Object.assign(parameter, this.queryParam, extra)).then(
          result => {
            return result.data
          }
        )
      },
      editObj: {}
    }
  },
  created () {
    goodsPartitionList().then(res => {
      this.partitionList = res.data
    })
    goodsListAll({ pageNum: 1, pageSize: 1000000, showAdmin: true }).then(
      res => {
        if (res.success) {
          this.goodsAll = res.data.list
        } else {
        }
      }
    )
  },
  components: {
    PageView,
    STable
  },
  methods: {
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    async batchUpDown (enabled) {
      if (!this.selectedRowKeys.length) { return this.$message.error('请选择商品！') }
      for (let index = 0; index < this.selectedRows.length; index++) {
        const row = this.selectedRows[index]
        row.enabled = enabled
        const extra = {
          admin: this.admin || undefined,
          uid: this.uid || undefined
        }
        await goodsUpdate({ ...row, ...extra })
      }
      this.$message.success('操作成功')
      this.$refs.table.refresh(false)
      this.selectedRowKeys = []
      this.selectedRows = []
    },
    filterOldOption (input, option) {
      console.info('option', option, input)
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    handleSelectOld (value) {
      this.goodsAll.forEach(it => {
        if (it.id === value) {
          it.show = true
          this.preview.image = it.picture
          this.edit = JSON.parse(JSON.stringify(it))
        }
      })
    },
    updateEnabled (record) {
      record.enabled = !record.enabled
      const extra = {
        admin: this.admin || undefined,
        uid: this.uid || undefined
      }
      goodsUpdate({ ...record, ...extra }).then(res => {
        this.handleRes(res)
      })
    },
    customRequest (fileData) {
      // TODO 1 上传到OSS
      filePreUpload()
        .then(response => {
          const data = response.data
          const url = data.url
          const prefix = data.key
          delete data.url
          delete data.key
          const array = fileData.file.name.replace(' ', '').split('.') // 把文件名数组化
          let suffix = '' // 后缀
          if (array.length > 1) {
            suffix = `.${array.pop().toLowerCase()}`
          }
          const formData = new FormData()
          Object.keys(data).forEach(it => {
            formData.append(it, data[it])
          })
          const key = `${prefix}/${uuid()}${suffix}` // 图片访问路径
          formData.append('key', key)
          formData.append('file', fileData.file)
          uploadOSS(url, formData)
            .then(response => {
              // 上传成功，设置图片访问路径
              this.preview.image = 'http://cache.wodh.cn/' + key
              this.$message.success('上传成功.')
              return fileData.onSuccess() // 上传成功了
            })
            .catch(error => {
              this.$message.error('上传异常.' + error)
              return fileData.onError() // 拒绝上传操作
            })
        })
        .catch(error => {
          this.$message.error('授权异常.' + error)
          return fileData.onError() // 拒绝上传操作
        })
      // TODO 2 引入 lrz 在前端压缩图片 获得图片的base64后保存到服务端
      /* if (data.file.size /1024 > 1024 * 2) {
        this.$message.error("请上传小于2M的文件");
        return;
      }
      const that = this;
      lrz(data.file).then(function(rst) {
        //成功时执行
        that.picture = rst.base64;
        console.log('rst', rst);
      }).catch(function(error) {
        //失败时执行
        console.log('error', error);
      }).always(function() {
        //不管成功或失败，都会执行
      }); */
    },
    deleteGoods (record) {
      record.flag = !record.flag
      const extra = {
        admin: this.admin || undefined,
        uid: this.uid || undefined
      }
      goodsUpdate({ ...record, ...extra }).then(res => {
        this.handleRes(res)
      })
    },
    update (record) {
      this.hasInsert = false
      record.show = true
      this.preview.image = record.picture
      this.edit = JSON.parse(JSON.stringify(record))
    },
    insert () {
      this.preview.image = ''
      this.edit = { show: true }
      this.hasInsert = true
    },
    handleCancel () {
      this.edit = { show: false }
    },
    handleOk () {
      const edit = this.edit
      edit.picture = this.preview.image
      edit.original = edit.price
      edit.enabled = false
      const extra = {
        admin: this.admin || undefined,
        uid: this.uid || undefined
      }
      if (this.hasInsert) {
        goodsInsert({ ...edit, ...extra }).then(res => {
          this.handleRes(res)
        })
      } else {
        goodsUpdate({ ...edit, ...extra }).then(res => {
          this.handleRes(res)
        })
      }
    },
    handleRes (res) {
      if (res.success) {
        this.$message.success('成功')
        this.$refs.table.refresh(false)
        this.edit = { show: false }
      } else {
        this.$message.error(res.msg)
      }
    },
    addEdit (record) {
      this.$set(this.editObj, record.id, JSON.parse(JSON.stringify(record)))
    },
    cancelEdit (id) {
      this.$set(this.editObj, id, null)
    },
    async editAdr (id) {
      const extra = {
        admin: this.admin || undefined,
        uid: this.uid || undefined
      }
      const res = await goodsUpdate({ ...this.editObj[id], ...extra })
      if (res.code === 0) {
        this.$message.success('修改成功')
        this.$set(this.editObj, id, null)
        this.$refs.table.refresh(false)
      } else {
        this.$message.error(res.msg)
      }
    }
  },
  computed: {
    title () {
      return this.$route.meta.title
    }
  }
}
</script>

<style lang="less" scoped>
.top5 {
  margin-bottom: 8px;
}
</style>
